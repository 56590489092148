@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@include spb.set-typography;

// Remove annoying 5px margin
body, html {
  height: 100%;
  width: 100%;
  margin: 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  align-items: center;
}
